import React, { useEffect, useState } from "react"
import Header from "./Header"
import Map from "./Map"
import "./App.css"
import { t } from "i18next"

function App() {
  const [windowHeight, setWindowHeight] = useState<number>(window.innerHeight) // Use to get correct height of mobile screen because of the navigation/address bar

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWindowHeight(window.innerHeight)
    })
  }, [])

  return (
    <div className="App">
      <title>{t("title")}</title>
      <div className="AppHeaderContainer" style={{ height: `${windowHeight * 0.1}px` }}>
        <Header />
      </div>
      <div className="AppMapContainer" style={{ height: `${windowHeight * 0.9}px` }}>
        <Map height={`${windowHeight * 0.9}px`} />
      </div>
    </div>
  )
}

export default App
