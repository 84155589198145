import Modal from "./Modal"
import { Owner } from "./Map"
import { t } from "i18next"

function CustomerInfoModal({
  customer,
  isOpen,
  onClose,
}: {
  customer?: Owner
  isOpen: boolean
  onClose: () => void
}) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div>
        <h2 style={{ marginBottom: "2.2rem" }}>{t("customerInfo")}</h2>
        <p style={{ marginBottom: "0.8rem" }}>{customer?.name}</p>
        <p>{customer?.email}</p>
      </div>
    </Modal>
  )
}

export default CustomerInfoModal
