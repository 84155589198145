import { t } from "i18next"
import logo from "./assets/logo.svg"
import "./Header.css"

function Header() {
  return (
    <header className="Header">
      <a href={t("homepageUrl")}>
        <img src={logo} alt="logo" className="HeaderLogo" />
      </a>
      <h1>{t("grazingMap")}</h1>
    </header>
  )
}

export default Header
