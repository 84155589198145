import React, { ReactChild } from "react"
import ReactModal from "react-modal"
import "./Modal.css"

function Modal({
  isOpen,
  onClose = () => {},
  children,
}: {
  isOpen: boolean
  onClose?: () => void
  children: ReactChild
}) {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="ModalContent"
      overlayClassName="ModalBackground"
    >
      {children}
    </ReactModal>
  )
}

export default Modal
