type GeoJsonPolygon = {
  type: "Polygon"
  coordinates: number[][][]
}

type LatLngPolygon = {
  mainPolygon: {
    lat: number
    lng: number
  }[]
  holes: {
    lat: number
    lng: number
  }[][]
}

// Convert GeoJson polygon to custom LatLng.
// WithId generates a unique id for every coordinate.
// When mapping all coordinates in polygon to markers in PastureEditor all markers needs
// unique keys that does not change when moving or adding points.
export function convertGeojsonPolygonToLatLng(geoJson: GeoJsonPolygon): LatLngPolygon {
  let polygon: LatLngPolygon = {
    mainPolygon: [],
    holes: [],
  }
  if (geoJson.coordinates[0]) {
    for (const coordinate of geoJson.coordinates[0]) {
      polygon.mainPolygon.push({
        lat: coordinate[1],
        lng: coordinate[0],
      })
    }
  }
  polygon.mainPolygon.pop() // Remove duplicate coordinate
  for (let i = 1; i < geoJson.coordinates.length; i++) {
    const holeCoordinates = geoJson.coordinates[i]
    let hole = []
    for (const coordinate of holeCoordinates) {
      hole.push({
        lat: coordinate[1],
        lng: coordinate[0],
      })
    }
    hole.pop() // Remove duplicate last coordinate
    polygon.holes.push(hole) // Hole coordinates must be reversed for google maps to count it as an exclusion zone and not a multi polygon
  }
  return polygon
}
