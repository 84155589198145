import { useEffect, useState } from "react"
import { GoogleMap, LoadScript, Marker, Polygon } from "@react-google-maps/api"
import iconCattle from "./assets/iconCattle.svg"
import iconSmall from "./assets/iconSmall.svg"
import userLocationIcon from "./assets/userLocation.svg"
import { convertGeojsonPolygonToLatLng } from "./convertGeoJson"
import CustomerInfoModal from "./CustomerInfoModal"
import ForceUserLocationModal from "./ForceUserLocationModal"
import MapInfo from "./MapInfo"

type Collar = {
  serialNo: number
  position: {
    type: "Point"
    coordinates: number[]
  }
  type: "small" | "cattle"
}

export type Owner = {
  name: string
  email: string
}

type Pasture = {
  id: number
  geometry: {
    type: "Polygon"
    coordinates: number[][][]
  } | null
  owner?: Owner
}

function Map({ height }: { height: string }) {
  const [userLocation, setUserLocation] = useState<{ lat: number; lng: number }>()
  const [zoom, setZoom] = useState<number>(6)
  const [collars, setCollars] = useState<Collar[]>([])
  const [pastures, setPastures] = useState<Pasture[]>([])
  const [showUserInfoModal, setShowUserInfoModal] = useState(false)
  const [selectedOwner, setSelectedOwner] = useState<Owner>()
  const [showForceUserLocationModal, setShowForceUserLocationModal] = useState(false)

  useEffect(() => {
    getUserLocation()
  }, [])

  useEffect(() => {
    getData()
  }, [userLocation]) // eslint-disable-line react-hooks/exhaustive-deps

  function getUserLocation() {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setUserLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        })
        setZoom(16)
        setShowForceUserLocationModal(false)
      },
      (error) => {
        console.log(error)
        setShowForceUserLocationModal(true)
      },
    )
  }

  async function getData() {
    if (!userLocation) {
      return
    }

    const [prodData, stagingData]: [
      { collars: Collar[]; pastures: Pasture[] },
      { collars: Collar[]; pastures: Pasture[] },
    ] = await Promise.all([
      await fetch(
        `https://account.nofence.no/api/open/data/?center=${userLocation.lng},${userLocation.lat}`,
        {
          method: "GET",
        },
      )
        .then((response) => {
          if (response.status !== 200) {
            throw Error("Request error")
          }
          return response.json()
        })
        .then((prodData) => {
          return prodData
        })
        .catch((error) => {
          console.log(error)
          return { collars: [], pastures: [], users: [] }
        }),
      await fetch(
        `https://account-staging.nofence.no/api/open/data/?center=${userLocation.lng},${userLocation.lat}`,
        {
          method: "GET",
        },
      )
        .then((response) => {
          if (response.status !== 200) {
            throw Error("Request error")
          }
          return response.json()
        })
        .then((stagingData) => {
          return stagingData
        })
        .catch((error) => {
          console.log(error)
          return { collars: [], pastures: [], users: [] }
        }),
    ])

    setCollars([...prodData.collars, ...stagingData.collars])
    setPastures([...prodData.pastures, ...stagingData.pastures])
  }

  return (
    <div>
      <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ""}>
        <GoogleMap
          mapContainerStyle={{
            height: height,
            width: "100vw",
          }}
          center={userLocation || { lat: 60, lng: 8 }}
          zoom={zoom}
          options={{
            fullscreenControl: false,
            //streetViewControl: false,
            rotateControl: false,
            mapTypeId: "satellite",
            minZoom: 6,
          }}
        >
          {pastures.map((pasture) => {
            if (!pasture.geometry) return null
            const latLngPolygon = convertGeojsonPolygonToLatLng(pasture.geometry)
            return (
              <Polygon
                key={`polygon+${pasture.id}`}
                paths={[latLngPolygon.mainPolygon, ...latLngPolygon.holes]}
                options={{
                  fillColor: "#64A70B",
                  fillOpacity: 0.4,
                  strokeColor: "#fff",
                  strokeOpacity: 1,
                  strokeWeight: 2,
                  zIndex: 1,
                }}
                onClick={() => {
                  const owner = pasture.owner
                  if (owner) {
                    setShowUserInfoModal(true)
                    setSelectedOwner(owner)
                  }
                }}
              />
            )
          })}
          {collars.map((collar) => {
            if (collar.position) {
              return (
                <Marker
                  key={`marker+${collar.serialNo}`}
                  position={{
                    lat: collar.position.coordinates[1],
                    lng: collar.position.coordinates[0],
                  }}
                  icon={collar.type === "small" ? iconSmall : iconCattle}
                  zIndex={10}
                  clickable={false}
                />
              )
            } else {
              return null
            }
          })}
          {userLocation && <Marker position={userLocation} icon={userLocationIcon} zIndex={20} />}

          <MapInfo onRefresh={getUserLocation} />
        </GoogleMap>
      </LoadScript>

      <CustomerInfoModal
        customer={selectedOwner}
        isOpen={showUserInfoModal}
        onClose={() => {
          setShowUserInfoModal(false)
          setSelectedOwner(undefined)
        }}
      />

      <ForceUserLocationModal isOpen={showForceUserLocationModal} onGetLocation={getUserLocation} />
    </div>
  )
}

export default Map
