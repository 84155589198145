import React, { useRef } from "react"
import "./Button.css"

function Button({ text, onClick }: { text: string; onClick: () => void }) {
  const buttonRef = useRef<HTMLButtonElement>(null)

  return (
    <button
      ref={buttonRef}
      onClick={(e) => {
        onClick()
      }}
      className="Button"
    >
      <h3 className="ButtonText">{text}</h3>
    </button>
  )
}

export default Button
