import React from "react"
import "./MapInfo.css"
import { t } from "i18next"
import refresh from "./assets/refresh.svg"

function MapInfo({ onRefresh }: { onRefresh: () => void }) {
  return (
    <div className="MapInfoContainer">
      <div className="MapInfoTextBackground">
        <p className="MapInfoText">{t("mapInfo")}</p>
      </div>
      <button onClick={(e) => onRefresh()} className="RefreshButton">
        <img src={refresh} alt="refresh" width={24} height={24} />
      </button>
    </div>
  )
}

export default MapInfo
