import React from "react"
import Modal from "./Modal"
import Button from "./Button"
import { t } from "i18next"

function ForceUserLocationModal({
  isOpen,
  onGetLocation,
}: {
  isOpen: boolean
  onGetLocation: () => void
}) {
  return (
    <Modal isOpen={isOpen}>
      <div>
        <h2 style={{ marginBottom: "2.2rem" }}>{t("userLocation.sharePosition")}</h2>
        <p style={{ marginBottom: "2.4rem" }}>{t("userLocation.sharePositionInfo")}</p>
        <Button text={t("userLocation.getLocation")} onClick={onGetLocation} />
      </div>
    </Modal>
  )
}

export default ForceUserLocationModal
