import i18n from "i18next"
import translationEN from "./en/translation"
import translationNO from "./nb/translation"
import translationES from "./es/translation"

const validLanguageCodes = ["en", "nb", "es"]

const resources = {
  // using a vague 'en' for english as we get en-US in the US and en-GB in uk
  en: {
    translation: translationEN,
  },
  nb: {
    translation: translationNO,
  },
  es: {
    translation: translationES,
  },
}

const LANGUAGE_DETECTOR = {
  type: "languageDetector",
  async: true,
  detect: (callback) => {
    let languageCode = navigator.language.toLowerCase()
    if (["nb", "no", "nn", "nn-no", "nb-no"].includes(languageCode)) {
      languageCode = "nb"
    }
    if (languageCode.includes("es")) {
      languageCode = "es"
    }
    if (!validLanguageCodes.includes(languageCode)) {
      languageCode = "en"
    }
    callback(languageCode)
  },
  init: () => null,
}

i18n
  .use(LANGUAGE_DETECTOR)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  })

export default i18n
